/*
  Noto Sans KR
*/
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: local(※), url('../fonts/NotoSansKR-Thin.woff2') format('woff2'),
    url('../fonts/NotoSansKR-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: local(※), url('../fonts/NotoSansKR-Light.woff2') format('woff2'),
    url('../fonts/NotoSansKR-Light.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: local(※), url('../fonts/NotoSansKR-Regular.woff2') format('woff2'),
    url('../fonts/NotoSansKR-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: local(※), url('../fonts/NotoSansKR-Medium.woff2') format('woff2'),
    url('../fonts/NotoSansKR-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: local(※), url('../fonts/NotoSansKR-Bold.woff2') format('woff2'),
    url('../fonts/NotoSansKR-Bold.woff') format('woff');
}
/*
    Roboto
*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local(※), url('../fonts/Roboto-Thin.woff2') format('woff2'),
    url('../fonts/Roboto-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(※), url('../fonts/Roboto-Light.woff2') format('woff2'),
    url('../fonts/Roboto-Light.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(※), url('../fonts/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/Roboto-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(※), url('../fonts/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/Roboto-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(※), url('../fonts/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/Roboto-Bold.woff') format('woff');
}
/*
    S-CoreDream
*/
@font-face {
  font-family: 'S-CoreDream';
  font-style: normal;
  font-weight: 300;
  src: local(※), url('../fonts/S-CoreDream-3Light.woff') format('woff');
}
@font-face {
  font-family: 'S-CoreDream';
  font-style: normal;
  font-weight: 400;
  src: local(※), url('../fonts/S-CoreDream-4Regular.woff') format('woff');
}
@font-face {
  font-family: 'S-CoreDream';
  font-style: normal;
  font-weight: 500;
  src: local(※), url('../fonts/S-CoreDream-5Medium.woff') format('woff');
}
@font-face {
  font-family: 'S-CoreDream';
  font-style: normal;
  font-weight: 700;
  src: local(※), url('../fonts/S-CoreDream-6Bold.woff2') format('woff2'),
    url('../fonts/S-CoreDream-6Bold.woff') format('woff');
}
@font-face {
  font-family: 'S-CoreDream';
  font-style: normal;
  font-weight: 900;
  src: local(※), url('../fonts/S-CoreDream-8Heavy.woff') format('woff');
}
/*
    SpoqaHanSans
*/
@font-face {
  font-family: 'SpoqaHanSans';
  font-style: normal;
  font-weight: 300;
  src: local(※), url('../fonts/SpoqaHanSans-Light.woff') format('woff');
}
@font-face {
  font-family: 'SpoqaHanSans';
  font-style: normal;
  font-weight: 400;
  src: local(※), url('../fonts/SpoqaHanSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'SpoqaHanSans';
  font-style: normal;
  font-weight: 700;
  src: local(※), url('../fonts/SpoqaHanSans-Bold.woff') format('woff');
}
