@keyframes opacity {
  0% {
    transform: 0;
  }
  100% {
    transform: translateY(0) rotate(360deg);
  }
}
@keyframes fill {
  0% {
    transform: translateY(-100%) rotate(0deg);
  }
  100% {
    transform: translateY(0) rotate(360deg);
  }
}
@keyframes slide-bottom {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes scale {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes wave-front {
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes wave-back {
  100% {
    transform: translate(50%, 0);
  }
}

@keyframes wave {
  0% {
    transform: translate(0, 108%);
  }
  100% {
    transform: translate(0, 0%);
  }
}
@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
  }
  20% {
    transform: translate3d(0, -5px, 0);
  }
  100%,
  40% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bgchange {
  from {
    background-color: transparent;
  }
  to {
    background-color: #fff;
  }
}
@keyframes pos_l {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes pos_r {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes morph {
  0%,
  100% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
}
@keyframes morph02 {
  0%,
  100% {
    border-radius: 80% 20% 70% 20%/70% 10% 40% 20%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  80% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

@keyframes bounce-left {
  0% {
    transform: translateX(-30px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateX(-15px);
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateX(-10px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateX(-6.5px);
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateX(-4px);
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    transform: translateX(0px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateX(0px);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes slide_up {
  0% {
    height: px(75);
    transform-origin: 0% 100%;
    background: linear-gradient(left, #24c3b6 0%, #3782ce 100%);
  }
  100% {
    height: 100%;
    transform-origin: 0% 100%;
    background: linear-gradient(left, #ff6256 0%, #ec574b 100%);
  }
}

@keyframes slide_down {
  0% {
    height: 100%;
    transform-origin: 0% 100%;
    background: linear-gradient(left, #ff6256 0%, #ec574b 100%);
  }
  100% {
    height: px(75);
    transform-origin: 0% 100%;
    background: linear-gradient(left, #24c3b6 0%, #3782ce 100%);
  }
}

@keyframes list_slide_up {
  0% {
    margin-top: 400px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes list_slide_dowm {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: 400px;
  }
}
@keyframes w-full-wrap {
  0% {
    width: 100%;
  }
  100% {
    width: auto;
  }
}
@keyframes w-full {
  0% {
    width: 100%;
  }
  100% {
    width: 65px;
    height: 65px;
    border-radius: px(32.5);
    -webkit-width: 65px;
    -webkit-height: 65px;
    -webkit-border-radius: px(32.5);
  }
}
@-webkit-keyframes w-full {
  0% {
    width: 100%;
  }
  100% {
    width: 65px;
    height: 65px;
    border-radius: 32.5px;
    -webkit-width: 65px;
    -webkit-height: 65px;
    -webkit-border-radius: 32.5px;
  }
}
@keyframes w-full02 {
  0% {
    width: auto;
  }
  100% {
    width: 0;
    height: 0;
  }
}
@keyframes w-full03 {
  0% {
    width: 0;
  }
  100% {
    width: auto;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: px(34);
  }
}
@keyframes typing02 {
  from {
    width: 0;
  }
  to {
    width: px(162);
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #15beb9;
  }
}
