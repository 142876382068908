@charset "utf-8";

@import './font';
@import './module';
@import './animation';

// base
*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  touch-action: manipulation;
  text-size-adjust: none;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strong,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

button,
select,
input,
textarea {
  color: #222;
}

html {
  font-size: 62.5%;
}

body {
  @include wfn;
  line-height: 1.35;
  font-size: px(16);
  letter-spacing: px(-0.5);
  color: #222;
  font-weight: 400;
  background-color: #f8f8f8 !important;

  &.bgf {
    background-color: #fff !important;

    .bg_body_full {
      display: none !important;
    }
  }
}

.bg_body_full {
  content: '';
  z-index: -1;
  display: block;
  @include pos(fixed, $l: 0, $t: 0, $b: 0, $r: 0);
  background-color: #fff;
}

html.static {
  .fix.btm {
    position: static !important;
  }

  .content {
    padding-bottom: 0 !important;
  }

  .ly_apt_join {
    // .fix.btm{
    //     position:fixed !important;
    // }
    .content {
      //padding-bottom:px(55) !important;
    }
  }
}

#pageWrap {
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

code,
.code {
  @include wfr;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}

img,
video,
audio,
object,
embed,
iframe {
  max-width: 100%;

  @include mq('fold') {
    width: 100%;
  }
}

img {
  height: auto;
  vertical-align: top;
}

i,
address {
  font-style: normal;
}

a,
s {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
strong,
b,
th {
  font-weight: 500;
}

.bold {
  font-weight: 500 !important;
}

.u {
  text-decoration: underline;
}

pre {
  @include wfn;
  word-break: break-all;
  white-space: pre-line;
}

legend,
caption,
.blind {
  @include blind;
}

.scroll {
  @include iscroll;
}

:focus {
  outline: 0;
}

.hide {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  clip: clip(-1px, -1px, -1px, -1px);
  position: absolute;
  left: -9999px;
}

em {
  font-style: normal;
}

// form
button,
select {
  text-transform: none;
}

button {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
  appearance: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  font-family: 'Noto Sans KR', Dotum, Gulim, Sans-serif;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

input[type='password'] {
  letter-spacing: px(0.5);
}

input[type='email'] {
  ime-mode: disabled;
}

select {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  font-weight: 500;
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

::placeholder {
  color: #a7a7a7;
}

::-webkit-search-cancel-button {
  display: none;
}

.swiper-wrapper {
  font-size: 0;
  text-align: center;
  white-space: nowrap;
}

.swiper-slide {
  display: inline-block;
}

// layout
#wrap {
  width: 100%;
  padding-top: px($head_height);
}

[class*='ly_apt_'] {
  .content {
    padding-top: px($head_height);

    .header {
      position: fixed !important;
      left: 0;
      top: 0;
      z-index: 10 !important;
      width: 100%;
      -webkit-transform: translateZ(0);
    }
  }
}

#header,
.ly_pop .header,
[class*='ly_apt_'] .header {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  min-height: px($head_height);

  h1 {
    flex: 1;
    text-align: center;
    font-size: px(18);
    padding-left: px(54);
    padding-right: px(54);
    font-weight: 400;
  }

  .select_wrap {
    position: relative;
    flex: 0 auto;
    margin: 0 auto;
    width: auto;

    span {
      font-size: px(18);
      line-height: 1;
      font-weight: 400;
    }

    select {
      text-align: center;
      text-align: center;
      font-size: px(18);
      line-height: 1;
      font-weight: 400;
      background: url($urlImg + 'ico_dropdown.svg') right center no-repeat;
    }
  }

  [class^='btn_'] {
    @include pos(absolute, $t: 50%);
    transform: translateY(-50%);
    font-size: 0;

    i {
      @include bgf;
      width: px(28);
      height: px(28);
    }
  }

  .btn_prev {
    left: px(13);

    i {
      @include svgfill(#222, 'ico_prev.svg');
    }
  }

  .btn_close {
    right: px(10);

    i {
      @include svgfill(#222, 'ico_x.svg');
    }
  }

  .btn_all_menu {
    right: px(16);

    i {
      @include svgfill(#222, 'ico_menu.svg');
    }
  }

  .btn_allim {
    right: px(58);

    i {
      @include svgfill(#222, 'ico_bell.svg');
    }

    &.new {
      &:after {
        content: '';
        @include iconNew;
        top: px(2);
        right: px(2);
      }
    }
  }

  .btn_set {
    right: px(48);

    i {
      @include svgfill(#222, 'ico_set.svg');
    }
  }
}

.header.ty_left {
  display: block;
  border-bottom: 0 !important;

  header {
    display: flex;
    position: relative;
    width: 100%;
    height: px(50);
    align-items: center;

    .tit {
      text-align: left !important;
      margin-left: px(54);
    }
  }
}

.fix {
  z-index: 10 !important;
  width: 100%;

  &.top {
    @include pos(fixed !important, $l: 0, $t: 0);
    transform: translate3d(0, 0, 0);

    &.line {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  &.btm {
    @include pos(fixed, $l: 0, $b: 0);
    transform: translate3d(0, 0, 0);
  }

  &.shrink {
    min-height: px(48) !important;

    h1 {
      line-height: px(48) !important;
      font-size: px(16) !important;
    }

    [class^='btn_'] {
      transform: translateY(-50%) scale(0.8) !important;
    }

    .menu_header {
      .btn_close {
        transform: translateY(-50%) scale(1) !important;
      }
    }
  }
}

.goOut {
  visibility: hidden;
  transition: 0.5s;

  &.on,
  &.must {
    visibility: visible;
    bottom: 0 !important;
  }
}

html.static {
  .fix.btm {
    position: static;
  }

  #wrap {
    padding-bottom: 0 !important;
  }
}

// popup
.ly_pop {
  display: none;
  z-index: 20;
  @include pos(fixed, $t: 0, $l: 0);
  transform: translateZ(0);
  -webkit-transform: translate3d(0, 0, 0);

  &,
  &:before,
  .shwBtn {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
  }

  &:before,
  .shwBtn {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:before {
    content: '';
    @include pos(absolute, $t: 0, $l: 0);
  }

  .wrap {
    width: auto;
    z-index: 1;
    position: absolute;
    left: 20px;
    right: 20px;
    top: 50%;
    @include bdr(25);
    background-color: #fff;
    text-align: left;
    transform: translate(0, -50%);
    transition: all 0.35s linear;

    &.bgt {
      background-color: $bg !important;

      #header,
      .header {
        background-color: $bg !important;
      }

      section,
      .section {
        background-color: transparent !important;
      }
    }
  }

  .close {
    @include pos(absolute, $r: px(15), $t: px(15));
    z-index: 1;

    i {
      @include bgf;
      width: px(28);
      height: px(28);
      @include svgfill(#222, 'ico_x.svg');
    }
  }

  &.show {
    .wrap {
      opacity: 1;
    }
  }

  &.popBtm {
    display: block !important;
    bottom: 0;
    top: auto;
    z-index: 20;
    transform: translateY(100vh);
    transition-delay: 0.35s;

    &:before,
    .shwBtn {
      position: fixed;
      opacity: 0;
      top: auto;
      bottom: 0;
    }

    .wrap {
      opacity: 0;
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transition-property: all;
      transition-timing-function: ease;
      transition-duration: 0.35s;
      transform: translateY(100%);
    }

    &.ndim {
      left: 0;
    }

    &.show {
      transform: translateY(0);
      transition-delay: 0s;

      &:before,
      .shwBtn {
        opacity: 1;
      }

      .wrap {
        opacity: 1;
        transform: translateY(0);
      }

      will-change: transform;
    }

    &.ly_footer {
      .wrap {
        border-top-left-radius: 0;
        border-top-right-radius: px(25);
        padding: px(40) px(20);

        .tit {
          font-size: px(14);
          font-weight: 500;

          u {
            color: #222;
          }
        }

        .txt {
          margin-top: px(12);
          font-size: px(12);
          line-height: px(22);
          color: #999;
        }
      }
    }

    &.ly_alert_b {
      .wrap {
        border-top-left-radius: 0;
        border-top-right-radius: px(25);
        padding: px(40) px(20);

        .tit {
          display: block;
          font-size: px(14);
          font-weight: 500;
          text-align: left;
        }

        .txt {
          margin-top: px(12);
          font-size: px(12);
          line-height: px(22);
          color: #999;
          text-align: left;
        }
      }
    }

    &.ly_terms {
      .wrap {
        padding: px(40) px(30) px(30);

        .content {
          padding: 0 !important;
        }

        .tit {
          font-size: px(18);
          font-weight: 700;
          line-height: px(28);
        }

        .chk_terms {
          margin-top: px(15);
          font-size: 0;
          text-align: left;

          .checkbox {
            display: inline-block;

            &.i_all {
              input {
                & + label {
                  width: px(26);

                  i {
                    width: px(26);
                    height: px(23);
                    vertical-align: px(-6);

                    &:before {
                      top: 0;
                      background-size: auto px(18) !important;
                    }
                  }
                }
              }

              .link {
                font-size: px(14);
                font-weight: 400;
                color: #666666;
              }
            }
          }
        }

        .btn_area_half {
          margin-top: px(35);
          display: block;

          .skip {
            width: auto;
            padding: 0 px(55);
            line-height: px(50);
            border-radius: px(25);
          }
        }
      }

      @include mq('small') {
        .wrap {
          br {
            display: none;
          }

          .btn_area_half {
            .skip {
              padding: 0 px(30);
            }
          }
        }
      }
    }
  }

  &.full {
    &:before {
      display: none;
    }

    .wrap {
      position: relative;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 0;
      transform: translate(0, 0);
      background-color: #fff;
      overflow-x: hidden;
    }

    .close {
      padding: px(8);
      top: 0;
      right: px(8);
    }

    .content {
      text-align: left;

      &,
      &.y_scroll {
        @include iscroll('y', $h: 100%);
      }

      .header {
        @include pos(sticky, $t: 0, $l: 0, $r: 0);
        z-index: 3;
      }
    }

    .btn_area_wide {
      padding: 0;
    }
  }

  &.ndim {
    display: block;
    right: auto;
    left: -2000px;
    width: 100%;

    .content {
      overflow: hidden;
      height: auto;
    }
  }

  .btn_area_wide {
    .skip {
      box-shadow: none !important;
    }
  }
}

.ly_alert {
  .wrap {
    background-color: transparent;

    .content {
      background-color: #fff;
    }
  }

  &,
  &_b {
    .content {
      text-align: center;

      .txt_wrap {
        min-height: px(170);
        padding: px(25);
        text-align: left;

        + .btn_area_wide {
          padding-top: 0;
        }

        .tit {
          display: block;
          font-size: px(18);
          font-weight: 700;
        }

        .sTxt {
          margin-top: px(12);
          font-size: px(14);
          line-height: 1.4;
          color: #666;
          font-weight: 300;
        }
      }
    }

    .btn_area_wide {
      .skip {
      }
    }
  }

  &.popBtm {
    .wrap {
      width: 86%;
      max-width: px(320);
      border-radius: px(20);
      bottom: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60px);
    }

    &.show {
      .wrap {
        transform: translate(-50%, -50%);
      }
    }
  }
}

.ly_loading {
  .wrap {
    background-color: transparent;
    transition: none;
    overflow: visible;
  }

  .content {
    overflow: visible !important;

    .loading {
      position: relative;
      overflow: hidden;
      width: px(100) !important;
      height: px(100) !important;
      border-radius: 50%;
      margin: 0 auto;
      background-color: #222;
      @include slideBorderCover;

      .logo {
        @include pos(relative);
        display: flex;
        height: px(100);
        overflow: hidden;
        justify-content: center;
        align-items: center;
        z-index: 5;

        &::after {
          content: '';
          width: px(37);
          height: px(41);
          @include bgf;
          @include svgfill(#fff, 'loading_logo.svg');
        }
      }

      .water {
        @include pos(absolute, $t: 0, $l: 0);
        width: 100%;
        height: 100%;
        background: #27d7c8;
        transform: translate(0, 50%);
        animation: wave 5s infinite linear;
      }

      .water_wave {
        width: 200%;
        position: absolute;
        bottom: 100%;

        &.water_wave_back {
          right: 0;
          fill: #27d7c8;
          opacity: 0.6;
          animation: wave-back 1.4s infinite linear;
        }

        &.water_wave_front {
          left: 0;
          fill: #27d7c8;
          margin-bottom: -1px;
          animation: wave-front 0.7s infinite linear;
        }
      }
    }
  }
}

.ly_toast {
  &:before {
    background-color: transparent;
  }

  .wrap {
    padding: 0 px(40) px(50);
    background-color: transparent;

    @include mq('small') {
      padding: 0 px(20) px(50);
    }
  }

  .content {
    @include bdr(5);
    padding-top: px(10);
    padding-bottom: px(10);
    background-color: rgba(34, 34, 34, 0.65);
    box-shadow: 0 px(3) px(6) rgba(0, 0, 0, 0.23);
    text-align: center;

    p {
      margin-bottom: 0;
      font-size: px(14);
      color: #fff;
    }
  }
}

// btn
.btn_area_wide,
.btn_area_half {
  position: relative;

  &.bgw {
    background-color: #fff;
  }

  button {
    width: 100%;
    flex: 1;
  }

  a {
    display: block;
    text-align: center;
  }

  button,
  a {
    padding: px(17) 0;
    position: relative;
    z-index: 1;
  }

  .skip {
    font-size: px(16);
    font-weight: 500;
    background-color: #222;
    color: #fff;

    &.point {
      background-color: $fcr;
    }
  }

  .normal {
    font-size: px(16);
    color: #7a7a7a;
  }

  &.more {
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      content: '';
      z-index: -1;
      width: 100%;
      @include pos(absolute, $b: 0, $l: 0);
    }

    &:before {
      top: px(-10);
      background: linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.9) 15%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    &:after {
      @include pos(absolute, $b: 0, $l: 0);
      background-color: #fff;
    }
  }

  &.half {
    padding-bottom: px(4);

    button {
      & ~ button {
        margin-top: px(8);
      }
    }
  }

  &.btm {
    padding: px(20);
    background-color: #fff;
    text-align: center;

    .skip {
      border-radius: px(8);

      &.line {
        padding: px(12.5) 0;
        border: 1px solid #b9b9b9;
        background-color: #fff;
        font-size: px(14);
        color: #999999;
      }

      & ~ .line {
        margin-top: px(15);
      }

      & ~ .btn_txt {
        margin-top: px(10);
      }
    }

    .btn_txt {
      width: auto;
      display: inline-block;
      padding-top: px(5);
      font-size: px(14);
      color: #999;
      position: relative;
      padding-bottom: px(3);
      color: #999;

      u {
        text-decoration: none;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: px(1);
        background-color: #999;
      }
    }
  }
}

.btn_area_half {
  display: flex;

  button,
  a {
    flex: 1;
    margin-left: px(10);

    &:first-child {
      margin-left: 0;
    }

    &.cancel {
      background-color: #efefef;
      border-radius: 8px;
      color: #222;
    }

    &.txt_s {
      height: px(55);
      line-height: px(55);
      padding: 0;
      font-size: px(14) !important;
    }
  }
}

.ly_alert {
  .btn_area_half {
    display: flex;

    li {
      flex: 1;

      button,
      a {
        margin-left: 0;
        border-radius: 0 !important;
        font-size: px(14);

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

// 입력 폼
.form {
  margin: 0 auto;

  .item {
    position: relative;

    & ~ .item {
      margin-top: px(10);
    }

    &.col02 {
      display: flex;

      .ip_box {
        flex: 0 px(226);

        & ~ .ip_box {
          flex: 1;
          margin-left: px(10);
        }
      }
    }

    .btn {
      @include pos(absolute, $t: 0, $r: 0);
      width: px(120);
      height: px(54);
      text-align: center;
      font-size: px(16);
      font-weight: 300;
      border-radius: px(12);
      color: #fff;
      background-color: #333;

      &:disabled {
        color: #a7a7a7;
        background-color: #dddddd;
      }
    }
  }
}

.lbl {
  display: none;
  @include pos(absolute, $t: 50%, $l: px(20));
  transform: translateY(-50%);
  font-size: px(16);
  font-weight: 300;
  color: #a7a7a7;

  &.code {
    font-weight: 500 !important;
    color: #cfcfcf;
  }

  &.hide {
    display: none !important;
  }
}

.select_box {
  position: relative;
  height: px(35);

  &:after {
    content: '';
    @include pos(absolute, $t: 50%, $r: px(7));
    width: px(20);
    height: px(20);
    transform: translateY(-50%);
    @include bgf;
    background-image: url($urlImg + 'bg_select.png');
  }
}

.ip_box {
  display: block;
  position: relative;
  border: px(1) solid #15beb9;
  background-color: #fff;
  width: 100%;

  input:disabled {
    border-color: #efefef;
    background-color: #efefef;
    color: rgba(34, 34, 34, 0.5);
    opacity: 1;
    -webkit-text-fill-color: inherit;

    & ~ .txt,
    & ~ .btn_txt {
      color: rgba(34, 34, 34, 0.5);
    }
  }

  .txt {
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: px(16);
    font-weight: 500;
    color: #222;

    & ~ .del {
      right: px(35) !important;
    }
  }

  .btn_srch {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: px(28);
    height: px(28);
    font-size: 0;
    background: url($urlImg + 'btn_ip_srch.png') center no-repeat;
    background-size: px(28) auto;

    & ~ .del {
      right: 0 !important;
    }
  }

  .time {
    @include pos(absolute, $t: 50%, $r: px(15));
    transform: translateY(-50%);
    font-size: px(16);
    font-weight: 500;
    color: #d31818;
    font-family: 'Roboto', 'Noto Sans KR', Dotum, Gulim, sans-serif;
  }

  .ipt {
    display: block;
    min-height: px(156);
    font-size: px(14);
  }

  input,
  .ipt {
    width: 100%;
    padding: px(13) px(20);
    font-size: px(16);
    font-weight: 500;
    color: #222;
  }

  &.focus {
    border: px(1) solid #e8e8e8;
    background-color: #fff;

    .lbl,
    button.pen {
      display: none !important;
    }

    .select_box {
      .lbl {
        display: block !important;

        &.hide {
          display: none !important;
        }
      }
    }
  }

  &.in {
    border: px(1) solid #e8e8e8;

    &.focus {
      &:after {
        content: '';
        @include pos(absolute, $t: 0, $l: 0);
        width: 100%;
        height: 100%;
        border: px(1) solid #efefef !important;
        transition: border 100ms;
      }

      .select_box {
        .lbl {
          display: none !important;

          &.show {
            display: block !important;
          }
        }
      }
    }
  }

  .select_box {
    .lbl {
      position: relative;
      top: 0;
      transform: translateY(0);
      line-height: px(35);
      background-color: #fff;
    }

    select {
      width: 100%;
      height: px(35);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      cursor: pointer;
      font-size: px(14);
      font-weight: 500;
      padding: 0 px(10);
      @include elip('l1');
    }

    .lbl {
      margin-top: 0;
      background-color: #fff;
    }
  }

  &.textarea_box {
    position: relative;

    .lbl {
      @include pos(absolute, $t: px(27), $l: px(20));
    }

    textarea {
      box-sizing: border-box;
      padding: px(20);
      width: 100%;
      min-height: px(160);
      font-size: px(16);
      font-weight: 300;
      color: #222;
    }
  }

  &.select_box {
    position: relative;

    &.in {
      border-color: #ddd !important;

      select {
        color: #222 !important;
      }
    }

    &.focus {
      &:after {
        content: '';
        @include iconArr(7, 7, #222);
        @include pos(absolute, $r: px(19.5), $t: 50%);
        transform: translateY(-50%) rotate(135deg);
        transition: 0.2s ease 0s;
      }
    }

    select {
      width: 100%;
      padding-top: px(13);
      padding-bottom: px(13);
      color: #a7a7a7;
      font-weight: 400;
    }

    &:after,
    &.in:after {
      content: '';
      @include iconArr(7, 7, #222);
      @include pos(absolute, $r: px(19.5), $t: 50%);
      transform: translateY(-50%) rotate(135deg);
      transition: 0.2s ease 0s;
    }
  }

  &.ip_line {
    box-sizing: border-box;
    border: 0;
    border-radius: 0;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: px(1);
      background-color: #cdcdcd;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: px(1);
      background-color: #222;
      transition-duration: 1s;
    }

    &.focus {
      box-shadow: none;

      &:after {
        width: 100%;
      }
    }

    .ipt,
    input {
      padding: px(7) px(40) px(7) px(0);
      font-size: px(16);
      font-weight: 500;
    }

    .lbl {
      left: px(0);
    }
  }

  &.ip_del {
    //padding-right:px(30);
    backface-visibility: hidden;

    button.del {
      display: none;
      font-size: 0;
      z-index: 2;
      @include pos(absolute, $t: 50%, $r: px(40));
      transform: translateY(-50%);

      i {
        position: relative;
        display: inline-block;
        width: px(22);
        height: px(22);
        background: url($urlImg + 'btn_ip_del.png') center no-repeat;
        background-size: 100% auto;
      }

      &.on {
        display: block;
      }
    }
  }

  .btn_txt {
    z-index: 1;
    @include pos(absolute, $t: 50%, $r: px(15));
    transform: translateY(-50%);
    font-size: px(16);
    color: #2bbcb0;

    &:disabled {
      color: #999;
    }
  }
}

/* range slider */
.range_slider {
  @include pos(relative);
  width: 100%;
  height: px(6);
  margin: 0 auto;
  background-color: #e8e8e8;
  border-radius: px(5);
  margin-bottom: px(8);
  margin-top: px(3);

  .ui-widget-header {
    display: block;
    @include pos(absolute, $t: 0);
    height: 100%;
    background-color: #2bbcb0;
    background-position: 0 0;
    border: 0;
    z-index: 1;
  }

  .ui-slider-handle {
    @include pos(absolute, $t: 50%);
    width: px(15);
    height: px(15);
    margin-left: px(-6);
    @include bdr($o: 50);
    border: px(1) solid #e8e8e8;
    transform: translateY(-50%);
    background-color: #fff;
    cursor: pointer;
    z-index: 2;
    touch-action: none;
    box-shadow: 0 px(1) px(6) rgba(0, 0, 0, 0.14);

    &:nth-of-type(1) {
      margin-left: px(-1);
    }

    &:last-child {
      margin-left: px(-12);
    }
  }
}

.slider_lbl {
  @include pos(relative);
  height: px(15);
  margin: px(0) auto px(25);
  padding: 0;

  li {
    @include pos(absolute);
    text-align: center;
    font-size: px(12);
    font-weight: 500;
    color: #999;
    transform: translateX(-50%);
    min-width: px(40);

    &:nth-child(odd) {
      color: #222;
    }

    &:first-child {
      left: 0;
      transform: translateX(0%);
      text-align: left;
    }

    &:last-child {
      left: 100%;
      transform: translateX(-100%);
      text-align: right;
    }

    &:nth-of-type(2) {
      left: 25%;
    }

    &:nth-of-type(3) {
      left: 50%;
    }

    &:nth-of-type(4) {
      left: 75%;
    }
  }
}

.gnb_wrap {
  position: relative;
  width: 100%;
  height: px(71);
  background: url($urlImg + 'bg_gnb.png') top center no-repeat;
  background-size: auto px(71);
  z-index: 10 !important;

  &.off {
    background: #fff;

    .logo {
      display: none;
    }
  }

  .btn_wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: px(66);

    [class*='btn'] {
      flex: 1;
      text-align: center;
      font-size: px(12);
      color: #222;

      &:before {
        content: '';
        display: block;
        margin: 0 auto px(3);
        width: px(24);
        height: px(22);
        background-repeat: no-repeat;
        background-position: center;
      }

      &.active {
        font-weight: 700;
      }
    }

    .btn_info01 {
      &:before {
        background-image: url($urlImg + 'icon_gnb01.png');
        background-size: px(21) px(22);
      }

      &.active {
        &:before {
          background-image: url($urlImg + 'icon_gnb01_on.png');
        }
      }
    }

    .btn_info02 {
      &:before {
        background-image: url($urlImg + 'icon_gnb02.png');
        background-size: px(24) px(17);
      }

      &.active {
        &:before {
          background-image: url($urlImg + 'icon_gnb02_on.png');
        }
      }
    }
  }

  .logo {
    position: absolute;
    top: px(43);
    left: 50%;
    transform: translateX(-55%);
    z-index: 2;
    width: px(20);
    height: px(20);
    font-size: 0;
  }
}

.rdo_wrap {
  display: flex;
  align-items: center;

  .rdo_item {
    flex: 1;
    height: px(35);
    position: relative;
    border: 1px solid #e8e8e8;
    border-left: 0;

    i {
      color: #999;
      font-size: px(14);
    }

    &:first-of-type {
      border-left: 1px solid #e8e8e8;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      @include pos(absolute, $l: 0, $t: 0);
      width: 100%;
      height: 100%;
      font-size: 16px;
      border: 0;
      text-align: center;
      background: #fff;
      cursor: pointer;
    }

    input[type='radio'] {
      @include pos(absolute, $l: 0, $t: 0);
      width: 100%;
      height: 100%;
      border: 0;
      opacity: 0;
      -webkit-appearance: none;
      z-index: -1;
    }

    input:checked + label {
      color: #fff;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      left: -1px;
      top: -1px;
      border: 1px solid #15beb9;
      background-color: #15beb9;
      font-weight: 500;

      i {
        color: #fff;
      }
    }
  }
}

.btn_srch_full {
  box-sizing: border-box;
  position: relative !important;
  top: 0 !important;
  transform: none !important;
  width: 100%;
  height: px(50);
  border: px(1) solid #efefef;
  text-align: left;
  padding: 0 px(15);

  span {
    font-size: px(14);
    color: #999;

    &.val_txt {
      font-size: px(16);
      color: #222;
      font-weight: 500;
    }
  }

  &:after {
    content: '';
    @include bgf;
    width: px(28);
    height: px(28);
    position: absolute;
    top: 50%;
    right: px(15);
    transform: translateY(-50%);
    @include svgfill(#222, 'btn_srch.svg');
  }

  &.focus {
    border: px(2.5) solid #2bbcb0;
  }
}

.table_wrap {
  table {
    td,
    th {
      text-align: center;
    }

    .a_l {
      text-align: left;
    }
  }

  thead {
    tr {
      th {
        padding: px(6) px(10);
        border-bottom: px(2) solid #111;
        font-size: px(12);
        color: #999;
        font-weight: 400;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: px(9) px(10);
        border-bottom: px(1) solid #ececec;
        font-size: px(12);
        color: #222;
        font-weight: 400;

        &.empty,
        .empty {
          @include wfn;
        }
      }
    }
  }
}

.sub_footer {
  padding: px(30) px(20);
  background-color: #f4f4f4;

  .tit {
    font-size: px(12);
    font-weight: 500;
    color: #222;
  }

  .txt {
    margin-top: px(15);
    font-size: px(11);
    font-weight: 300;
    line-height: px(18);
    color: #999;
  }
}

.btn_top {
  opacity: 0;
  position: fixed;
  bottom: px(15);
  right: px(15);
  z-index: 20;
  width: px(50);
  height: px(50);
  @include bgf;
  background-color: #999999;
  box-shadow: 0 px(3) px(10) rgba(0, 0, 0, 0.2);
  background-image: url($urlImg + 'btn_top.png');
  background-size: px(14) px(20);
  background-position: center;
  transition: all 0.3s;

  &.active {
    opacity: 1;
  }
}

.iframe_wrap {
  padding: px(25) px(20);
}

.bbs_list {
  padding: px(10) px(20) px(40);

  li {
    padding: px(18) 0;
    border-bottom: px(1) solid #efefef;

    .tit_wrap {
      position: relative;
      width: 100%;
      display: block;
      text-align: left;

      .tit {
        @include elip('l1');
        max-width: 90%;
        display: block;
        font-size: px(16);
        font-weight: 400;
        color: #222222;
      }

      .date {
        font-size: px(14);
        font-weight: 300;
        color: #999;
      }

      &:after {
        content: '';
        position: absolute;
        top: -3px;
        right: 0;
        width: px(28);
        height: px(28);
        @include bgf;
        background-image: url($urlImg + 'icon_arr02.png');
        transition: all 0.3s;
      }
    }

    &.toggle_wrap {
      .btn_toggle {
        &:after {
          transform: rotate(90deg);
        }
      }

      .view_toggle {
        padding: px(20) 0;
        border-top: px(1) solid #efefef;
        font-size: px(14);
      }

      &.active {
        border-bottom: px(1) solid #222;

        .btn_toggle {
          .tit {
            font-weight: 700;
          }

          &:after {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }

  div.tit_wrap {
    &:after {
      display: none !important;
    }
  }
}

.tab_wrap {
  padding: px(25) px(20) 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  li {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    flex: 1;
    padding-right: px(4.5);
    padding-left: px(4.5);

    .btn {
      width: 100%;
      display: block;
      padding: px(11) 0;
      text-align: center;
      border-radius: px(5);
      border: px(1) solid #e8e8e8;
      font-size: px(14);
      color: #999;

      &.on {
        color: #fff;
        border-color: #222;
        background-color: #222;
      }
    }
  }

  & ~ .bbs_list {
    padding-top: px(20);

    li {
      &:first-child {
        border-top: px(1) solid #efefef;
      }
    }
  }
}

.view_tit_wrap {
  padding: px(25) px(20);
  text-align: center;
  background-color: #f8f8f8;

  .tit {
    display: block;
    @include elip('l1');
    font-size: px(18);
    font-weight: 500;
  }

  .sub_txt {
    margin-top: px(3);

    span {
      display: block;
      font-size: px(14);
      color: #999;
    }
  }
}

.view_txt_list {
  padding: px(19) px(20);
  background-color: #f8f8f8;

  ul {
    li {
      position: relative;
      padding-left: px(10);
      font-size: px(14);
      color: #999;

      &:before {
        content: '-';
        position: absolute;
        top: 0;
        left: 0;
      }

      & ~ li {
        margin-top: px(3);
      }
    }
  }
}

.checkbox {
  @include checkbox;
  transform: translateZ(0);

  input + label {
    line-height: px(19);
    font-size: px(14);
    color: #666;

    i {
      box-sizing: border-box;
      position: relative;
      width: px(19);
      height: px(19);
      display: inline-block;
      vertical-align: middle;
      margin-right: px(5);

      &:before {
        content: '';
        @include pos(absolute, $t: px(2), $l: 0);
        @include bgf;
        width: px(15);
        height: px(11);
        background: url($urlImg + 'icon_chk.png') center no-repeat;
        background-size: 100% auto !important;
      }
    }
  }

  input:checked + label {
    i {
      &:before {
        background: url($urlImg + 'icon_chk_on.png') center no-repeat;
      }
    }
  }

  &.i_all {
    position: relative;
    display: block;

    input + label {
      line-height: px(24);
      width: auto;
      font-weight: 500;
      font-size: px(16);
      color: #282a2d;

      i {
        width: px(28);
        height: px(24);
        margin-right: px(3);
        vertical-align: px(-6);

        &:before {
          content: '';
          @include pos(absolute, $t: px(1), $l: 0);
          @include bgf;
          width: 100%;
          height: 100%;
          background: url($urlImg + 'icon_all_chk.png') center no-repeat;
          background-size: auto 23px !important;
        }
      }
    }

    input:checked + label {
      i {
        &:before {
          background: url($urlImg + 'icon_all_chk_on.png') center no-repeat;
        }
      }
    }
  }

  &.i_sub {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    label {
      flex: 1;
      line-height: 1.7;
      padding-right: px(10);
    }

    .btn {
      flex: 0 px(52);
      height: px(25);
      line-height: 1;
      font-size: px(12);
      color: #999;
      padding: px(3) px(14);
      border: px(1) solid #999;
      border-radius: px(12);
    }
  }
}

// 체크동의
.terms_list {
  padding: px(20) 0;

  &.active {
    .btn_toggle {
      transform: rotate(0);
    }
  }

  .btn_toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: px(28);
    height: px(28);
    @include bgf;
    background-image: url($urlImg + 'btn_term_toggle.png');
    transform: rotate(180deg);
    transition: 0.3s;
  }

  .item_list {
    margin-top: px(10);
    padding: px(5) 0 0 px(8);

    li {
      & ~ li {
        margin-top: px(6);
      }
    }
  }
}

.term_info_wrap {
  ul {
    padding-top: px(20);
    border-top: px(1) solid #eaeaea;

    li {
      position: relative;
      padding-left: px(10);
      font-size: px(14);
      line-height: px(22);
      color: #999;

      &.point {
        color: #d31818;
      }

      & ~ li {
        margin-top: px(10);
      }

      &:before {
        content: '*';
        position: absolute;
        top: px(3);
        left: 0;
      }
    }
  }
}

.ly_select_wrap {
  .wrap {
    border-top-left-radius: 0;
  }
}

.ly_apt_filter .content.pop_sel_wrap,
.ly_point_filter .content.pop_sel_wrap {
  padding-top: px(40) !important;
}

.pop_sel_wrap {
  padding: px(40) px(20) px(20) !important;
  padding-top: px(40) !important;

  &.content {
    padding-top: px(40) !important;
  }

  .list {
    @include iscroll('y');
    max-height: px(250);
    min-height: px(160);

    .item {
      box-sizing: border-box;
      width: 100%;
      height: px(48);
      text-align: left;
      @include checkbox;
      vertical-align: top;

      & ~ .item {
        margin-top: px(5);
      }

      input {
        & + label {
          display: flex;
          align-items: center;
          width: 100%;
          height: px(50);
          line-height: 1;
          padding: 0 px(15);
          border-radius: px(5);
          font-size: px(14);
          font-weight: 400;

          span {
            flex: 1;
            @include elip('l1');
            line-height: 1;
            padding-right: px(20);
          }

          code {
            flex: 0 auto;
            margin-top: px(2);
          }

          .txt {
            font-size: px(15);
          }
        }

        &:checked + label {
          background-color: #f8f8f8;
        }
      }
    }
  }
}

.tb_apt {
  width: 100%;
  border-top: px(1) solid #707070;

  thead {
    th {
      padding: px(11) 0;
      font-size: px(12);
      color: #222;
      font-weight: 400;
      text-align: center;
      background-color: #ececec;
    }
  }

  tbody {
    tr {
      height: px(35);

      td {
        font-size: px(12);
        line-height: 1.3;
        text-align: center;
        color: #222;
        border-bottom: px(1) solid #ececec;
        word-break: keep-all;
      }
    }
  }
}

.btn_wrap {
  text-align: center;

  .r_btn {
    margin: 0 auto;
    min-width: px(70);
    height: px(26);
    border-radius: px(13);
    border: px(1) solid #999;
    font-size: px(14);
    color: #999;
  }
}

.ly_email {
  .wrap {
    overflow: visible;

    .content {
      overflow: visible;
      border-radius: 2rem 2rem 0 0;

      .txt_wrap {
        padding: px(33) px(20) px(25);

        .tit,
        .sTxt {
          text-align: center;
        }

        .chk_terms {
          margin-top: px(18);

          .link {
            font-size: px(14);
            font-weight: 300;
            color: #666;
          }
        }

        .form_wrap {
          display: flex;
          margin-top: px(20);

          .form {
            + .form {
              margin-left: px(3);
            }

            .dropdown {
              width: px(124);
            }
          }

          .ip_box {
            border: 2px solid #d6d6d6;

            .lbl {
              left: px(13);
            }

            input {
              padding: px(13) px(15);
            }

            &.focus {
              border-color: #222;
            }
          }
        }
      }
    }
  }

  .btn_area_wide {
    .skip {
      border-radius: 0 0 2rem 2rem;
    }
  }
}

.dropdown {
  position: relative;

  &.on {
    .dropdown_header {
      border-color: #222;
    }

    .dropdown_content {
      display: block;
    }
  }

  &_header {
    border: px(2) solid #d6d6d6;

    > button {
      position: relative;
      width: 100%;
      height: 100%;
      padding: px(12) px(20) px(14) px(15);
      text-align: left;
      color: #999;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: px(12);
        width: px(10);
        height: px(15);
        background: url('../images/bg_dropdown.svg') no-repeat 0 0/1rem;
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  &_content {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: px(1);
    background: #fff;
    border: px(2) solid #222;
    border-top: none;
    transform: translateY(100%);
    z-index: 5;

    button {
      display: flex;
      width: 100%;
      height: px(38);
      align-items: center;
      padding: 0 px(15);
      font-size: px(14);
      color: #666;

      &.selected {
        background: #e6fff9;
      }
    }
  }
}

.bottom_banner {
  z-index: 5;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  a {
    display: block;
    text-align: center;
    padding: px(20);
  }
  img {
    width: 100%;
    max-width: 500px;
  }
}

.spinner {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: '';
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-right-color: transparent;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
